@media (768px <= width < 992px) {
  $header-height: 4rem;

  header {
    height: $header-height;
  }

  .nav-brand {
    font-size: 1.5rem;
  }

  main {
    padding-top: $header-height;
  }

  hr {
    width: 75%;
  }

  h1 {
    font-size: 3.3rem;
    line-height: 55px;
  }

  h2 {
    font-size: 2.5rem;
  }

  .designation {
    margin-top: 7px;
    font-size: 1.4rem;
    text-align: left;
    line-height: 25px;
  }

  .welcome-section{
    min-height: calc(100vh - $header-height);

    .btn-explore-more {
      border-radius: 40px;
      border: none;
      background-color: var(--yellow);
      padding: 10px 32px;
      font-size: 1.2rem;
      transition: 0.3s ease-in-out;

      svg {
        margin-left: 1rem;
      }

      &:hover {
        background-color: #2BAE66FF;
      }
    }

    .welcome_image{
      height: 250px;
    }
  }

  .about-me {
    p {
      font-size: 1.3rem;
      text-align: center;
    }
  }

  .my-projects {
    .project-web {
      height: 350px;
      display: flex;
      flex-direction: column;
    }
    .project-image{
      height: 300px;
    }
    .project-name{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
    .project-mobile {
      height: 216px;
    }
  }

  section.services {
    .service-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: 14rem;

      .image{
        width: 35%;
        height: 250px;
        
        img {
          object-fit: cover;
          object-position: center;
        }
      }
      
      .content {
        width: 63%;
        padding: 1rem;

        h3 {
          font-size: 1.75rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }

  .meeting-main {
    padding-top: $header-height;
  }
  section.industries{
    .heading{
      font-size: 40px;
      font-weight: 500;
      text-align: start;
      filter: none;
      padding-left: 15px;
    } 
    .image{
      max-width: 100%;
      max-height: 300px;
    }
    p{
      font-size: 22px;
      padding-left: 15px;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.3);
    }
  }
}

 