@import './App-lg.scss';
@import './App-md.scss';
@import './App-sm.scss';
@import './App-xs.scss';

  .App, footer {
    font-family: 'Rubik', sans-serif;
  }

  .modal-image {
    transition: 0.5s ease-in-out;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-image:hover {
    transition: 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    font-weight: 700;
    color: white !important;
  }

  .image-slider{
    height:80vh
  }

  .full-image-modal-app {
    height: 90vh !important;
  }
  
  .full-image-modal {
    height: 90vh ;
  }
  
  .detail-modal {
    height: 80vh ;
  }

  .detail-modal::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .detail-modal::-webkit-scrollbar-track {
    display: none;
  }
  
  /* Handle */
  .detail-modal::-webkit-scrollbar-thumb {
    background: rgb(73, 73, 73);
    width: 8px !important;
    transition: 0.25s ease-in-out;
  }
  
  /* Handle on hover */
  .detail-modal::-webkit-scrollbar-thumb:hover {
    background: #093a66;
  }

  .object-cover {
    object-fit: cover;
  }

  .object-contain {
    object-fit: contain;
  }

  .object-top {
    object-position: top;
  }

  .object-center {
    object-position: center;
  }

  .object-bottom {
    object-position: bottom;
  }

  @media (min-width: 768px) {
    .w-md-75 {
      width: 75% !important;
    }
  }
  
  @media (max-width: 768px) {
    .full-image-modal-app {
      height: 100vh !important;
    }
  }
  
  @media (max-width: 991px) {
    .detail-modal {
      height: 100vh!important;
    }
    .image-slider{
      height: 100vh !important;;
    }
  }
  
  @media (max-width: 1200px) {
    .full-image-modal {
      height: 100vh !important;
    }
    .full-image-modal-app {
      height: 100vh !important;
    }
  }

  header {
    position: fixed;
    z-index: 11;
    display: flex;
    align-items: center;
    background-color: #101820FF;
    width: 100%;

    .nav-desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    }

    .nav-brand {
      font-family: 'Ubuntu', sans-serif;
      font-weight: bolder;
      text-transform: uppercase;
    }

    .btn-lets-meet {
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 40px;
      border: solid 2px transparent;
      background-color: var(--yellow);
      width: 140px;
      height: 40px;
      font-size: 1rem;
      color: var(--dark);
      text-decoration: none;
      transition: 0.3s ease-in-out;
    }

    .nav-link-desktop {
      position: relative;
      padding: 8px 0;
      color: var(--light);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: 2px;
        background-color: var(--yellow);
        transition: .2s ease-in-out;
      }

      &:hover::before {
        left: 0;
        right: 0;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: 2px;
        background-color: var(--light);
        transition: .2s ease-in-out;
      }
      
      &.active {
        filter: drop-shadow(0 0 5px #e7e7e793);

        &::after {
          left: 0;
          right: 0;
        }
      }

    }

    .gradient-border {
      content: "";
      display: block;
      height: 4px;
      background-image: url('./assets/textcolor.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .nav-mobile {
      position: fixed;
      top: 0;
      z-index: 12;
      background-color: #101820FF;
      width: 100vw;
      height: 100vh;
      transition: 0.3s ease-in-out;
      
      &[data-slide="true"] {
        right: 0;
        opacity: 1;
      }
      &[data-slide="false"] {
        right: 100%;
        opacity: 0;
      }

      .nav-links {
        margin-top: 5rem;

        li {
          padding: 0.75rem 0;
          font-family: 'DM Sans', sans-serif;
          font-weight: 600;
          cursor: pointer;

          button {
            border-radius: 40px;
            background-color: var(--yellow);
            padding: 0.45rem 2rem;
            font-size: 1rem;
            font-weight: 500;
            color: var(--dark);
          }
        }
      }
    }
  }

  section.welcome {
    background-image: url("./assets/main_image.jpg");
    background-size: cover;
    background-position: top;

    .welcome_image {
      position: relative;
      border-radius: 8px;
      background-image: url('./assets/layout4.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 100%;
      max-width: 500px;
      filter: blur(0.5px) drop-shadow(0 0 0.5rem #1464dc);
  
      .shadow-circle-1, .shadow-circle-2 {
        position: absolute;
        z-index: -11;
        left: 50px;
        width: 40%;
        height: 40%;
      }
      .shadow-circle-1 {
        top: -45px;
        background: linear-gradient(180deg, #2BAE66FF 100%);
        filter: blur(100px);
      }
      .shadow-circle-2 {
        top: 190px;
        background: linear-gradient(180deg,#FCF6F5FF 100%);
        filter: blur(85px);
      }
    }
    .btn-explore-more{
      color:'#FEE715FF'
    }
  }

  h2 {
    font-weight: 700;
    color: var(--light);
    text-align: center;
    filter: drop-shadow(0 0 1px var(--yellow));
  }

  .nav-brand {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
  }


  section.about-me {
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: top;
    height: 70vh;
    min-height: 30rem;

    p {
      max-width: 900px;
      color: var(--light);
    }
  }

  footer {
    position: relative;
    // margin-top: 3rem;
    background-color: var(--dark);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-image: url('./assets/textcolor.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 2px;
    }

    .footer-nav-brand {
      font-family: 'Ubuntu', sans-serif;
      font-size: 1.5rem;
      font-weight: bolder;
      text-transform: uppercase;
    }

    li.linked-in {
      svg {
        width: 1.7rem;
      }
    }
  }

  .button {
    background-color: #0b4a82;
    padding: 0.45rem 2rem;
    color: #ffffffcf;
    box-shadow: none !important;
  }

  .button:hover {
    background-color: #093a66;
  }

  .button svg {
    opacity: 0;
    margin-left: -1.5rem;
    transition: .3s ease-in-out;
  }

  .button:hover svg {
    opacity: 1;
    margin-left: 1rem;
  }

  .cardMain {
    border-radius: 8px;
  }

  .react-stars {
    display: flex !important;
    gap: 5px !important;
  }

  .slider {
    position: fixed;
    top: 10px;
    right: 20px;
    width: 150px;
    color: white;
    font-family: sans-serif;
    text-align: right;
  }

  .slider input {
    width: 100%;
    margin-bottom: 5px;
  }

  .my-projects {
    .project-web, .project-mobile {
      display: flex;
      justify-content: center;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }
  
    .project-mobile {
      height: 215px;
    }

    .para {
      opacity: 0;
      // background-color: rgba(11, 74, 130, 0.8);
      color: var(--light);
      text-decoration: none;
      transition: 0.5s ease-in-out;
    }
  }

  section.services {
    .service-card {
      overflow: hidden;
      border-radius: .5rem;
      background-color: #ffffff10;
    }
  }

  .gradient-text {
    background-image: url('./assets/textcolor.png');
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  @media (min-width: 768px) {
    .w-md-75 {
      width: 75% !important;
    }
  }

  .pagination {
    justify-content: center;
    margin-bottom: 0 !important;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    padding: 10px;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  ul.pagination li a {
    text-decoration: none;
    color: var(--yellow);
    font-size: 20px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  ul.pagination li.active a {
    color: var(--light);
  }

  ul.pagination li.active {
    background-color: var(--yellow);
  }



  .page-selection {
    width: 48px;
    height: 30px;
    color: var(--yellow);
  }

  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .meeting-main {
    .nav-brand {
    top: 3%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    width: 420px;
    height: 10rem;
    font-weight: bolder;
    font-size: 2.5rem;
    font-family: 'Ubuntu', sans-serif;
    text-transform: uppercase;
    text-align: center;
  }
}

.btn-project {
  width:100px;
  height: 35px;
  border-radius: 20px;
  color: white;
  border: 1px solid var(--yellow);
  transition: 0.3s ease-in-out;
}
.btn-project:hover{
  background-color: var(--yellow);
  color: black;
  transition: 0.3s ease-in-out;
}

.project-image:hover > .card-projects {
  opacity: 1;
  transition: 0.3s ease-in-out;
}

.card-projects {
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.project-name {
  background-color: white;
}
.project-name:hover{
  background-color: var(--yellow);
}
.project-name:hover > .card-projects {
  opacity: 0;
}
 