@media (width < 576px) {
  $header-height: 4rem;

  header {
    height: $header-height;
  }

  .nav-brand {
    font-size: 1.5rem;
  }

  main {
    padding-top: $header-height;
  }

  hr {
    width: 75%;
  }

  h1 {
    font-size: 2.4rem;
    text-align: center;
    line-height: 40px;
  }

  h2 {
    font-size: 2.2rem;
  }

  .designation {
    margin-top: 7px;
    font-size: 1.2rem;
    text-align: center;
    line-height: 20px;
  }

  .welcome-section{
    min-height: auto;

    .btn-explore-more {
      border-radius: 40px;
      border: none;
      background-color: var(--yellow);
      padding: 10px 32px;
      font-size: 1.2rem;
      transition: 0.3s ease-in-out;

      svg {
        margin-left: 1rem;
      }

    }

      .welcome_image{
        height: 200px;
      }
  }

  .about-me {
    p {
      font-size: 1.1rem;
      text-align: justify;
    }
  }

  .my-projects {
    .project-web {
      margin-left: auto;
      margin-right: auto;
      height: 350px;
      max-width: 300px;
      display: flex;
      flex-direction: column;
    }

    .project-image{
      height: 300px;
    }
    .project-name{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
    }
    .project-mobile {
      height: 216px;
    }
  }

  section.services {
    .service-card {
      margin: auto;
      max-width: 300px;
      text-align: center;
      
      .image{
        width: 100%;
        height: 200px;

        img {
          object-fit: cover;
          object-position: center;
        }
      }

      .content {
        padding: 1.25rem 1rem .75rem;

        h3 {
          font-size: 1.75rem;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
  }

  .meeting-main {
    padding-top: $header-height;
  }

  section.industries{
    .heading{
      font-size: 40px;
      font-weight: 500;
      text-align: start;
      filter: none;
      padding-left: 15px;
    } 
    .image{
      max-width: 100%;
      max-height: 300px;
    }
    p{
      font-size: 22px;
      padding-left: 15px;
    }
    .content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      top:0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.3);
    }
    
  }
}

 